import { FC } from 'react';
import inProgressIcon from '../../assets/images/in-progress.svg';

const Inprogress: FC = () => {
  return (
    <div
      className=' p-4'
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '100px 0' }}
    >
      <div className='text-center bg-white p-6 h-fit'>
        <div style={{ fontWeight: '600', fontSize: '22px', color: '#050505' }}>
          Thank you for pre-ordering your videos!
        </div>
        <p>&nbsp;</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={inProgressIcon} alt='giftIcon' />
        </div>

        <div style={{ color: '#707070', fontSize: '16px', maxWidth: '600px' }}>
          <p>&nbsp;</p>

          <div>
            Your videos will be available shortly after your graduation ceremony. 
          </div>
          <p>&nbsp;</p>
          <div>
            You will receive an email notifying that your videos are ready. 
          </div>
          <div>If you have any issues with your videos, please email</div>
          <a href='mailto:support@gradcut.com'>support@gradcut.com</a>
          <p>&nbsp;</p>
          <p>The Gradcut Team</p>
        </div>
      </div>
    </div>
  );
};

export default Inprogress;
