import { FC, useState } from 'react';
import university_logo from '../../assets/images/university_logo.svg';
import logo_updated from '../../assets/images/logo_updated.svg';
import Otp from '../../components/OTP';
import APIService from '../../network/APIService';
import CircularProgressWithLabel from '../../components/CircularProgressWithLabel';
import { toast } from 'react-toastify';

interface LoginProps {
  onLogin: () => void;
}

interface LoginData {
  universityEmail: string;
  studentId: string;
}

interface LoginResponse {
  email: string;
}

const Login: FC<LoginProps> = ({ onLogin }) => {
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [loginErrorClass, setLoginErrorClass] =
    useState<string>('no-login-error');
  const [loginResponse, setLoginResponse] = useState<LoginResponse>({
    email: '',
  });
  const [loginData, setLoginData] = useState<LoginData>({
    universityEmail: '',
    studentId: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const [agreements, setAgreements] = useState({
    receiveEmails: false,
    privacyAndCookies: false,
  });

  const handleLogin = async () => {
    onLogin();
  };

  const handleOnChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateLoginData = () => {
    if (!loginData.studentId || !loginData.universityEmail) {
      alert('Please fill in all the fields');
    } else {
      login();
    }
  };

  const handleLoginError = () => {
    setLoginErrorClass('login-error');
  };

  const resetLoginError = () => {
    setLoginErrorClass('no-login-error');
  };

  const login = async () => {
    setLoading(true);
    try {
      const response = await APIService.login(loginData);
      if (response.status === 200) {
        resetLoginError();
        setLoginResponse(response.data);
        setLoggedIn(true);
      } else {
        toast.error('Email or Student code already exist.');
        handleLoginError();
      }
    } catch (error) {
      handleLoginError();
    } finally {
      setLoading(false);
    }
  };

  const validateOtpInput = (otp: string[]) => {
    const otpValues = otp.filter((value) => value !== '');
    if (otpValues.length < 6) {
      toast.error('Please fill in the OTP field');
    } else {
      verifyOtp(otpValues);
    }
  };

  const verifyOtp = async (otp: string[]) => {
    setLoading(true);
    try {
      const mergeOtp = otp.join('');
      const response = await APIService.verifyOtp({
        otp: mergeOtp,
        studentId: loginData.studentId,
      });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('userId', response.data.userId);
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('studentCode', loginData.studentId);
        handleLogin();
      } else {
        toast.error('Invalid OTP');
      }
    } catch (error: any) {
      console.error('OTP Verification error', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAgreementChange = (e: any) => {
    setAgreements({
      ...agreements,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      {loggedIn ? (
        <Otp
          loading={loading}
          handleLogin={validateOtpInput}
          loginData={loginData}
          loginResponse={loginResponse}
          login={login}
        />
      ) : (
        <>
          <div className='flex flex-col md:flex-row'>
            <div
              className='flex items-center justify-center w-full md:w-1/2'
              style={{ backgroundColor: '#FAFAFA' }}
            ></div>
            <div className='flex items-center justify-start w-full md:w-1/2 p-8 bg-grayLight md:bg-transparent xl:px-52'>
              <img src={logo_updated} alt='logo' />
            </div>
          </div>
          <div
            className='flex flex-col md:flex-row'
            style={{ height: 'calc(100vh - 20vh)' }}
          >
            <div className='flex items-center justify-center w-full md:w-1/2 bg-grayLight'>
              <img
                src={university_logo}
                alt='University of Sydney'
                className='h-64 md:h-256 lg:h-auto'
              />
            </div>
            <div className='flex items-center justify-center w-full md:w-1/2 p-8'>
              <div className='w-[30rem]'>
                <div className='text-3xl mb-6 font-[500]'>
                  Pre-order Your Graduation Videos
                </div>

                <div className='text-sm mb-6 font-[400] text-[#707070]'>
                  To login enter your Student ID and University Email
                </div>
                <form>
                  <div className='mb-4'>
                    <label
                      className='block text-gray-700 text-sm font-medium mb-2 text-[14px] leading-[24px]'
                      htmlFor='studentId'
                    >
                      Student ID
                    </label>

                    <input
                      className='appearance-none border h-14 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='studentId'
                      name='studentId'
                      type='text'
                      placeholder='Student ID'
                      style={{ borderColor: '#D5D5D9', borderRadius: '8px' }}
                      value={loginData.studentId}
                      onChange={handleOnChangeInput}
                    />
                  </div>
                  <div className='mb-6'>
                    <label
                      className='block text-gray-700 text-sm font-medium mb-2 text-[14px] leading-[24px]'
                      htmlFor='studentId'
                    >
                      University Email
                    </label>
                    <input
                      className='appearance-none border h-14 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                      id='email'
                      type='email'
                      name='universityEmail'
                      placeholder='University Email'
                      style={{ borderColor: '#D5D5D9', borderRadius: '8px' }}
                      value={loginData.universityEmail}
                      onChange={handleOnChangeInput}
                    />
                  </div>

                  <div className='flex items-center justify-between mb-6'>
                    <button
                      className={`${
                        agreements.receiveEmails && agreements.privacyAndCookies
                          ? 'bg-primary'
                          : 'bg-gray'
                      } w-full text-white h-14 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline`}
                      type='button'
                      style={{ borderRadius: '8px' }}
                      onClick={validateLoginData}
                      disabled={
                        !agreements.receiveEmails ||
                        !agreements.privacyAndCookies
                      }
                    >
                      {loading ? <CircularProgressWithLabel /> : 'Login'}
                    </button>
                  </div>

                  <div className='mb-4'>
                    <label className='block mb-2' htmlFor='receiveEmails'>
                      <input
                        className='mr-2 leading-tight'
                        type='checkbox'
                        id='receiveEmails'
                        name='receiveEmails'
                        checked={agreements.receiveEmails}
                        onChange={handleAgreementChange}
                      />
                      <span className='text-sm font-[400] text-[#707070]'>
                        I agree to receive emails from Gradcut.
                      </span>
                    </label>
                  </div>

                  <div className='mb-4'>
                    <label className='block mb-2' htmlFor='privacyAndCookies'>
                      <input
                        className='mr-2 leading-tight'
                        type='checkbox'
                        id='privacyAndCookies'
                        name='privacyAndCookies'
                        checked={agreements.privacyAndCookies}
                        onChange={handleAgreementChange}
                      />
                      <span className='text-sm font-[400] text-[#707070]'>
                        By submitting this form, I agree to the processing of my
                        personal data by Gradcut as described in the{' '}
                        <a
                          href='https://gradcut.com/privacy-policy/'
                          className='text-[#707070] underline'
                        >
                          Privacy Statement
                        </a>{' '}
                        and{' '}
                        <a
                          href='https://www.gradcut.com/cookie-statement/'
                          className='text-[#707070] underline'
                        >
                          Cookie Policy
                        </a>
                        .
                      </span>
                    </label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Login;
