import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import {
  MediaControlBar,
  MediaController,
  MediaFullscreenButton,
  MediaLoadingIndicator,
  MediaMuteButton,
  MediaPlaybackRateButton,
  MediaPlayButton,
  MediaTimeRange,
} from 'media-chrome/dist/react';
import { FC, useEffect, useState } from 'react';
import { VideoModal } from '../../components/VideoModal';

const data = [
  {
    id: 60556,
    fileName: '1-1.mp4',
    fullPath: `${process.env.PUBLIC_URL}/video/1-1.mp4`,
    thumbnailPath:`${process.env.PUBLIC_URL}/thumbnails/1-1.jpg`,
    studentId: 9891,
    graduationId: 78,
    format: '1-1',
    preview: true,
    priceId: `${process.env.REACT_APP_1_1_PRICE_ID}`,
  },
  {
    id: 60557,
    fileName: '9-16.mp4',
    fullPath: `${process.env.PUBLIC_URL}/video/9-16.mp4`,
    thumbnailPath: `${process.env.PUBLIC_URL}/thumbnails/9-16.jpg`,
    studentId: 9891,
    graduationId: 78,
    format: '9-16',
    preview: false,
    priceId: `${process.env.REACT_APP_9_16_PRICE_ID}`,
  },
  {
    id: 60559,
    fileName: '16-9.mp4',
    fullPath: `${process.env.PUBLIC_URL}/video/16-9.mp4`,
    thumbnailPath: `${process.env.PUBLIC_URL}/thumbnails/16-9.jpg`,
    studentId: 9891,
    graduationId: 78,
    format: '16-9',
    preview: false,
    priceId: `${process.env.REACT_APP_16_9_PRICE_ID}`,
  },
];

const PreSalePayment: FC = () => {
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIP_PUBLIC_KEY}`);

  const [isModelOpen, setIsModelOpen] = useState({
    isCopyLink: false,
    isVideo: false,
  });

  const [cutData, setCutData] = useState<any[]>(data);
  const [isMobile, setIsMobile] = useState(false);
  const [allChecked, setAllChecked] = useState(false);
  const [atLeastOneChecked, setAtLeastOneChecked] = useState(false);

  const [videoData, setVideoData] = useState({
    fullPath: '',
    thumbnailPath: '',
    format: '',
  });

  const handleProceedToPayment = async () => {
    let priceIds = cutData
      .filter((d: any) => d.selected)
      .map((d: any) => d.priceId);

    if (priceIds.length > 2) {
      priceIds = [`${process.env.REACT_APP_PACKAGE__PRICE_ID}`];
    }

    const userId = localStorage.getItem('userId');
    const email = localStorage.getItem('email');
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/checkout-session`,
      { userId, email, priceIds }
    );
    if (response.data.sessionId) {
      const stripe = await stripePromise;
      if (stripe) {
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId,
        });
        if (result.error) {
          console.error('Stripe checkout error:', result.error.message);
        }
      }
    }
  };

  const handleCheckboxChange = (index: any) => {
    const updatedData = [...cutData];
    updatedData[index].selected = !updatedData[index].selected;
    setCutData(updatedData);

    const allSelected = updatedData.every((item) => item.selected);
    console.log(allSelected);
    setAllChecked(allSelected);

    const atLeastOneSelected = updatedData.some((item) => item.selected);
    setAtLeastOneChecked(atLeastOneSelected);
  };

  useEffect(() => {
    const checkIfMobile = () => {
      const userAgent =
        typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
      setIsMobile(/iPhone|iPad|iPod|Android/i.test(userAgent) ? true : false);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const handleOpenModal = (object: any) => {
    if (!isMobile) {
      setIsModelOpen((prev) => ({
        ...prev,
        isVideo: true,
      }));
    }

    setVideoData({
      fullPath: object.fullPath,
      thumbnailPath: object.thumbnailPath,
      format: object.format,
    });
  };

  const handleSetIsModelClose = () => {
    setIsModelOpen((prevState: any) => {
      const newState: any = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = false;
      });

      return newState;
    });
  };

  const videoReady = async (video: any) => {
    video.video_ready = true;
    setCutData([...cutData]);
  };

  const reorderedCutData =
    cutData &&
    cutData.length > 0 &&
    cutData.sort((a, b) => {
      if (a.format === '16-9' && b.format !== '16-9') return -1;
      if (a.format !== '16-9' && b.format === '16-9') return 1;
      if (a.format === '9-16' && b.format !== '9-16') return 1;
      if (a.format !== '9-16' && b.format === '9-16') return -1;
      return 0;
    });

  return (
    <div>
      {isModelOpen.isCopyLink && (
        <div className='fixed inset-0 bg-black opacity-50 z-50'></div>
      )}

      {isModelOpen.isVideo && (
        <div className='fixed inset-0 bg-borderColor opacity-1 z-50'></div>
      )}

      <div className='flex flex-col md:flex-row justify-between px-4 md:px-16 space-y-4 md:space-y-0'>
        <div>
          <div className='font-semibold text-2xl text-gray-900 flex items-center'>
            Preview Your Graduation Videos
          </div>
          {allChecked ? (
            <div
              className='bg-lightPink p-4 rounded-lg mt-2'
              style={{ width: 'fit-content' }}
            >
              <span className='font-normal text-dimGrayText text-lg'>Get</span>{' '}
              <span className='bg-primary text-white p-2 rounded-full mx-2'>
                33% off
              </span>
              <span className='font-normal text-dimGrayText text-lg'>
                For 3 Clip Bundle Purchase
              </span>{' '}
            </div>
          ) : (
            <div
              className='bg-lightPink p-4 rounded-lg mt-2'
              style={{ width: 'fit-content' }}
            >
              <span className='font-normal text-dimGrayText text-lg'>
                3 - Clips Bundle -
              </span>{' '}
              <span className='text-primary font-medium text-lg'>$49.95</span>
              <span className='bg-primary text-white p-2 rounded-full ml-2'>
                33% off
              </span>
            </div>
          )}
        </div>
        <div className='flex justify-center md:justify-end'>
          <button
            className={`bg-primary w-full md:w-auto h-10 text-white font-medium py-2 px-16 rounded focus:outline-none focus:shadow-outline ${
              !atLeastOneChecked
                ? 'opacity-50 cursor-not-allowed'
                : 'opacity-100 cursor-pointer'
            }`}
            type='button'
            onClick={() => handleProceedToPayment()}
            disabled={!atLeastOneChecked}
          >
            Proceed to payment
          </button>
        </div>
      </div>

      <div className='p-2'>
        <div className='flex justify-center flex-wrap'>
          {reorderedCutData &&
            reorderedCutData.length > 0 &&
            reorderedCutData.map((object, index) => (
              <div key={index} className='w-full md:w-1/2 lg:w-1/3 p-4'>
                <div className='bg-white rounded-lg  grid grid-cols-1 gap-0  px-4'>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      {object.format === '9-16' && (
                        <>
                          <span className='font-semibold text-2xl text-black'>
                            Portrait
                          </span>{' '}
                          <span className='font-normal text-lg text-dimGrayText'>
                            {object.format.replace('-', ':')}
                          </span>
                        </>
                      )}
                      {object.format === '1-1' && (
                        <>
                          <span className='font-semibold text-2xl text-black'>
                            Square
                          </span>{' '}
                          <span className='font-normal text-lg text-dimGrayText'>
                            {object.format.replace('-', ':')}
                          </span>
                        </>
                      )}
                      {object.format === '16-9' && (
                        <>
                          <span className='font-semibold text-2xl text-black'>
                            Landscape
                          </span>{' '}
                          <span className='font-normal text-lg text-dimGrayText'>
                            {object.format.replace('-', ':')}
                          </span>
                        </>
                      )}
                    </div>
                    <div className='text-primary font-medium text-lg'>
                      $24.95
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <div className='flex items-center my-4'>
                      <input
                        id={`checkbox-${index}`}
                        type='checkbox'
                        value=''
                        className='w-4 h-4 bg-gray-100 border-ash rounded focus:ring-2 accent-primary'
                        checked={object.selected}
                        onChange={() => handleCheckboxChange(index)}
                      />
                      <label
                        htmlFor={`checkbox-${index}`}
                        className='ml-2 text-sm font-medium text-black'
                      >
                        Purchase this video without watermark
                      </label>
                    </div>
                  </div>
                  <div
                    className='flex items-center justify-center'
                    style={{ display: object.video_ready ? 'none' : 'none' }}
                  >
                    <p className='text-center text-lg font-bold'>Loading ...</p>
                  </div>
                  <div className='flex items-center justify-center'>
                    <div
                      style={
                        object.format === '1-1'
                          ? { height: '350px', width: '350px' }
                          : object.format === '9-16'
                          ? { width: '240px' }
                          : undefined
                      }
                    >
                      <MediaController
                        defaultSubtitles
                      >
                        <video
                          slot='media'
                          src={object.fullPath}
                          preload='auto'
                          muted
                          crossOrigin=''
                          onCanPlay={() => videoReady(object)}
                          onClick={() => handleOpenModal(object)}
                        >
                          <track
                            label='thumbnails'
                            default
                            kind='metadata'
                            src={object.fullPath}
                          />
                          <track
                            label='English'
                            kind='captions'
                            srcLang='en'
                            src='./vtt/en-cc.vtt'
                          />
                        </video>

                        <MediaLoadingIndicator
                          noautohide
                          slot='centered-chrome'
                          style={{
                            '--media-loading-indicator-icon-height': '350px',
                          }}
                        ></MediaLoadingIndicator>
                        {isMobile && (
                          <MediaControlBar>
                            <MediaPlayButton></MediaPlayButton>
                            <MediaTimeRange></MediaTimeRange>
                            <MediaMuteButton></MediaMuteButton>
                            <MediaPlaybackRateButton></MediaPlaybackRateButton>
                            <MediaFullscreenButton></MediaFullscreenButton>
                          </MediaControlBar>
                        )}
                      </MediaController>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {isModelOpen.isVideo && (
              <VideoModal
                handleSetIsModelClose={handleSetIsModelClose}
                videoData={videoData}
              />
            )}
        </div>
      </div>
    </div>
  );
};

export default PreSalePayment;
