import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import TestImage from './assets/images/logoPlaceholder.png';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import ProtectedArea from './components/ProtectedArea';
import APIService from './network/APIService';
import Inprogress from './pages/Inprogress';
import Login from './pages/Login';
import PreSalePayment from './pages/PreSalePayment';

// Replace with fetched data:
interface MyObject {
  title: string;
  image: string;
}

const arrayOfObjects: MyObject[] = [
  {
    title: 'Horizontal(16:9)',
    image: TestImage,
  },
  {
    title: 'Square(1:1)',
    image: TestImage,
  },
  {
    title: 'Vertical(4:5)',
    image: TestImage,
  },
];

function App() {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [accessibleRoute, setAccessibleRoute] = useState<string[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const handleLogin = (): void => {
    setLoggedIn(true);
    window.location.reload();
  };

  useEffect(() => {
    const fetchUser = async () => {
      const studentCode = localStorage.getItem('studentCode');
      const user = await APIService.getLoggedInUser(studentCode);
      setLoggedIn(user ? true : false);
      setAccessibleRoute(user?.data?.accessibleRoutes || []);
      setLoading(false);
    };
    fetchUser().catch(console.error);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isLoggedIn) {
    return (
      <Routes>
        <Route path='/' element={<Login onLogin={handleLogin} />} />
      </Routes>
    );
  }

  return (
    <div className='h-screen'>
      <Navbar />
      <Routes>
        <Route path='/' element={<Navigate to={accessibleRoute[0]} />} />
        {accessibleRoute.includes('/inprogress') && (
          <Route
            path='/inprogress'
            element={
              <ProtectedArea>
                <Inprogress />
              </ProtectedArea>
            }
          />
        )}
        {accessibleRoute.includes('/preview') && (
          <Route
            path='/preview'
            element={
              <ProtectedArea>
                <PreSalePayment />
              </ProtectedArea>
            }
          />
        )}
        {/* Redirect to default accessible route if the path is not matched */}
        <Route path='*' element={<Navigate to={accessibleRoute[0]} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
