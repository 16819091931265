import {
  FC,
  useState,
  createRef,
  useEffect,
  ChangeEvent,
  useCallback,
} from 'react';
import CountdownTimer from '../DownDownTimmer/DownTownTimmer';
import CircularProgressWithLabel from '../CircularProgressWithLabel';
import logo_updated from '../../assets/images/logo_updated.svg';

interface OtpProps {
  handleLogin: (otpValues: string[]) => void;
  login: () => void;
  loading: boolean;
  loginData: any;
  loginResponse: any;
}

const Otp: FC<OtpProps> = ({
  handleLogin,
  loading,
  loginData,
  loginResponse,
  login,
}) => {
  const numerOfInputs = 6;

  const otpInputRefs = Array.from({ length: numerOfInputs }, () =>
    createRef<HTMLInputElement>()
  );

  const [currentIndex, setCurrentIndex] = useState(0);
  const [otp, setOtp] = useState(() =>
    Array.from({ length: numerOfInputs }, () => '')
  );

  const handleTimerExpire = useCallback(() => {
    // Perform any action when the timer reaches zero
  }, []);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (value.length > 1 && index > 0) {
      return;
    }
    if (value.length > 1) {
      const otpval = value.substring(0, numerOfInputs);

      setOtp(otpval.split(''));
      setCurrentIndex(numerOfInputs - 1);
      return;
    }

    setOtp((otp) => {
      const retval = otp.map((symbol, otpIndex) =>
        otpIndex === index ? value : symbol
      );

      return retval;
    });
  };

  useEffect(() => {
    if (otp.join('').length === numerOfInputs) {
      handleLogin(otp);
    }
    const currentInput = otpInputRefs?.[currentIndex]?.current;
    if (!(currentInput && currentInput.value)) {
      return;
    }
    currentInput.value = otp[currentIndex];

    const nextIndex =
      currentIndex < numerOfInputs - 1 ? currentIndex + 1 : currentIndex;
    const nextInput = otpInputRefs?.[nextIndex]?.current;

    if (nextInput) {
      nextInput.focus();
      nextInput.select();
    }
  }, [otp]);

  useEffect(() => {
    // focus the firs iput initially
    if (otpInputRefs?.[0]?.current) {
      otpInputRefs?.[0]?.current?.focus();
      otpInputRefs?.[0]?.current?.select();
    }
  }, []);

  return (
    <>
      <div className=' py-8 px-16 bg-white flex justify-center items-center lg:justify-start'>
        <a href='https://gradcut.com' className='h-full object-contain'>
          <img
            src={logo_updated}
            alt='Gradcut Logo'
            className='h-full object-contain'
          />
        </a>
      </div>
      <div className='flex flex-col items-center justify-center mt-16'>
        <div className='w-[375]'>
          <div className='flex text-3xl mb-6 font-[500] justify-center'>
            Verify Your Account
          </div>

          <div className='text-center text-sm mb-1 font-[300] text-[#707070]'>
            We have sent a One Time Password OTP to <br />
            <div className='font-bold my-1' style={{ color: 'black' }}>
              {loginResponse.email}
            </div>
          </div>
          <div className='text-center text-sm mb-6 font-[300] text-[#707070]'>
            Enter the code below to confirm your email address
          </div>
          <h1 className='text-[#707070] font-[400] font-regular text-center text-sm lg:text-base mt-5'>
            Code Expires in -
            <CountdownTimer
              initialTime={300}
              onTimerExpire={handleTimerExpire}
            />
          </h1>
          <div className='flex items-center justify-center mt-4'>
            {otpInputRefs.map((ref, index) => (
              <input
                key={index}
                ref={ref}
                className='w-12 h-10 md:w-16 md:h-12 lg:w-24 lg:h-16 border  rounded-lg   mx-3 text-center text-base md:text-xl lg:text-2xl focus:outline-none border-borderColor'
                type='number'
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange(e, index)
                }
                onFocus={() => setCurrentIndex(index)}
                onClick={(e) => {
                  setCurrentIndex(index);
                }}
                value={otp[index]}
              />
            ))}
          </div>
          <div className='flex items-center justify-center w-full mt-8 p-2'>
            <button
              className='bg-primary h-16 w-full text-white font-bold py-1 px-6  rounded-lg focus:outline-none focus:shadow-outline'
              type='button'
              onClick={() => handleLogin(otp)}
            >
              {loading ? <CircularProgressWithLabel /> : 'Verify'}
            </button>
          </div>
          <h1 className='font-regular text-center text-sm lg:text-base mt-5 text-[#707070]'>
            Didn't get the code?
            <span
              className='font-bold text-primary uppercase'
              style={{ cursor: 'pointer' }}
              onClick={login}
            >
              {' '}
              RESEND CODE
            </span>
          </h1>
        </div>
      </div>
    </>
  );
};

export default Otp;
