import React, { useState, useEffect } from 'react';
import APIService from '../../network/APIService';

const CountdownTimer = ({ initialTime, onTimerExpire }: any) => {
  const [time, setTime] = useState<any>(initialTime);
  const [timerExpired, setTimerExpired] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    let timerId: any;
    setTimerExpired(false);
    if (time > 0) {
      timerId = setInterval(() => {
        setTime((prevTime: any) => prevTime - 1);
      }, 1000);
    } else {
      onTimerExpire(); // Callback when the timer reaches zero
      setTimerExpired(true);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [time, onTimerExpire, reload]);

  const formatTime = (seconds: any) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(remainingSeconds).padStart(2, '0');

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  const onReSend = async () => {
    try {
      const obj = {
        otp: 'mergeOtp',
      };
      const response = await APIService.verifyOtp(obj);
      setReload(!reload);
      setTimerExpired(false);
      setTime(10);
      if (response && response.status === 200) {
        // localStorage.setItem('token', response.data.token);
      } else {
        // alert(response.response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  if (timerExpired) {
    return (
      <span
        className='font-bold'
        style={{ color: '#7B2C29' }}
        onClick={onReSend}
      >
        {' '}
        RESEND
      </span>
    );
  }
  return (
    <span className='font-bold' style={{ color: '#7B2C29' }}>
      {' '}
      {formatTime(time)}
    </span>
  );
};

export default CountdownTimer;
